<template>
  <div>
    <div style="height: calc(100vh - 145px); width: 100%; overflow: auto">
      <div v-for="(rack, rk) in racks" :key="`rack_${rk}`">
        <div class="d-flex flex-row mb-2">
          <div
            style="width: 60px"
            class="warning white--text font-weight-medium d-flex align-center justify-center mr-2"
          >
            Zone {{ rack.zone }} <br />
            Giá: {{ rack.position }}
          </div>
          <div style="width: calc(100% - 70px)">
            <table style="border-spacing: 0">
              <tr v-for="row in rack.rows" :key="`row_${rk}_${row.row}`">
                <td
                  v-for="cell in row.cells"
                  :key="`col_${rk}_${row.row}_${cell.cell_id}`"
                  :style="cell.cell_style"
                  class="td-item"
                  @click="getGoodsInfo(cell.cell_id)"
                >
                  {{ cell.cell_id }}
                </td>
              </tr>
              <!--<tr v-for="(ir, row) in rack.max_level" :key="`row_${rk}_${row}`">
                <td v-for="cell in rack.cells" :key="`col_${rk}_${row}_${cell}`" :style="getCellStyle(rack, row, cell)">
                  {{ getPositionCode(rack, row, cell) }}
                </td>
              </tr>-->
            </table>
          </div>
        </div>
      </div>
    </div>
    <v-row class="pt-3 align-center">
      <v-col cols="6" sm="4" md="2" lg="2">
        <SelectMultipleWarehouse
          :multiple="false"
          :label="$t('labels.warehouse')"
          :placeholder="$t('labels.warehouse')"
          name="id_warehouses"
          @onFilter="onWarehouseChange"
        />
      </v-col>
      <v-col cols="6" sm="4" md="2" lg="2">
        <SelectMultipleZoneByWarehouse
          :label="$t('labels.zone')"
          :placeholder="$t('labels.zone')"
          name="zones"
          :id-warehouses="filters.id_warehouses"
          @onFilter="onFilterChange"
        />
      </v-col>
      <v-col cols="6" sm="4" md="2" lg="2">
        <SelectMultipleCustomerByWarehouse
          :label="$t('labels.customer')"
          :placeholder="$t('labels.customer')"
          name="id_customers"
          :is-active-only="true"
          :id-warehouses="filters.id_warehouses"
          @onFilter="onFilterChange"
        />
      </v-col>
      <v-col
        cols="6"
        sm="4"
        md="2"
        lg="2"
        v-if="filters.id_warehouses && filters.id_warehouses.length > 0"
      >
        <v-btn color="error" @click="rollLocations">{{
          $t("labels.position_roll")
        }}</v-btn>
      </v-col>
      <v-col
        class="table-footer-count"
        cols="6"
        sm="4"
        md="2"
        lg="2"
        v-if="filters.id_warehouses && filters.id_warehouses.length > 0"
      >
        {{ countUsed }} / {{ countTotal }}
      </v-col>
    </v-row>

    <v-dialog v-model="goodsDialog" max-width="480px">
      <v-card>
        <v-card-title class="text-h6 mb-3"
          >{{ goodsInfo.cell_id }}
          {{ goodsInfo.sku ? " - " + goodsInfo.sku : "" }}</v-card-title
        >

        <v-card-text>
          <v-simple-table class="mb-5">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>{{ $t("labels.customer") }}</td>
                  <td>{{ goodsInfo.company_name }}</td>
                </tr>
                <tr>
                  <td>{{ $t("labels.sku") }}</td>
                  <td>
                    <SkuDownload v-if="goodsInfo.sku" :item="goodsInfo" />
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("labels.barcode") }}</td>
                  <td>{{ goodsInfo.customer_goods_barcode }}</td>
                </tr>
                <tr>
                  <td>{{ $t("labels.goods") }}</td>
                  <td>{{ goodsInfo.name }}</td>
                </tr>
                <tr>
                  <td>{{ $t("labels.goods_description") }}</td>
                  <td>{{ goodsInfo.description }}</td>
                </tr>
                <tr>
                  <td>{{ $t("labels.dxrxc_size") }}</td>
                  <td>{{ goodsInfo.dxrxc }} ({{ goodsInfo.weight }} gram)</td>
                </tr>
                <tr>
                  <td>{{ $t("labels.storage") }}</td>
                  <td>
                    {{ goodsInfo.storage_data }} ({{
                      $t("labels.storage_note")
                    }})
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("labels.other_position") }}</td>
                  <td>{{ goodsLocations }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { debounce } from "@/libs/helpers";
import { httpClient } from "@/libs/http";

export default {
  name: "WarehouseLocation",
  components: {
    SkuDownload: () => import("@/components/goods/SkuDownload"),
    SelectMultipleWarehouse: () =>
      import("@/components/administration/SelectMultipleWarehouse"),
    SelectMultipleCustomerByWarehouse: () =>
      import("@/components/administration/SelectMultipleCustomerByWarehouse"),
    SelectMultipleZoneByWarehouse: () =>
      import("@/components/common/SelectMultipleZoneByWarehouse"),
  },
  data: () => ({
    filters: {
      id_warehouses: null,
      id_customers: null,
    },

    dRacks: [],
    dLocations: [],

    racks: [],
    locations: [],
    styles: {},
    isLoading: false,

    goodsDialog: false,
    goodsInfo: {},
    goodsLocations: [],

    countUsed: 0,
    countTotal: 0,
  }),
  methods: {
    async getGoodsInfo(cell_id) {
      const id_warehouse = this.filters.id_warehouses[0];
      try {
        const { data } = await httpClient.post(
          "/warehouse-location/v1/goods-info-and-other-cells",
          {
            id_warehouse,
            cell_id,
          }
        );
        this.goodsInfo = { ...data.goods };
        this.goodsLocations = [...data.locations]
          .map((l) => l.cell_id)
          .join("; ");
        this.goodsDialog = true;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async rollLocations() {
      if (!confirm(this.$t("messages.confirm_roll_position_all"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post(
          "/warehouse-location/v1/roll-locations",
          this.filters
        );
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.location_recall_success"));
        this.getList();
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    onWarehouseChange(filter) {
      let values = filter.value;
      if (["string", "number"].includes(typeof filter.value)) {
        values = [filter.value];
      }
      this.filters = { ...this.filters, [filter.name]: values };
      this.getList();
    },
    onFilterChange(filter) {
      let values = filter.value;
      if (["string", "number"].includes(typeof filter.value)) {
        values = [filter.value];
      }
      this.filters = { ...this.filters, [filter.name]: values };
      this.filterList();
    },
    getCharByIndex(level) {
      return String.fromCharCode(level + 65);
    },
    getPositionCode(rack, row, cell) {
      return `${rack.zone || ""}${rack.position}${this.getCharByIndex(
        row
      )}${cell}`;
    },
    getCellStyle(rack, cell_id) {
      return {
        height: rack.size_z + "px",
        "min-height": rack.size_z + "px",
        width: rack.size_y + "px",
        "min-width": rack.size_y + "px",
        "font-size": (rack.size_y * 0.25 > 14 ? 14 : rack.size_y * 0.25) + "px",
        "background-color": this.styles[`${rack.zone}_${cell_id}`] || "",
      };
      // const cell_id = this.getPositionCode(rack, row, cell)
      /*const location = this.locations.find(l => l.cell_id == cell_id && l.zone == rack.zone)
      if (location && location.used_volume !== null) {
        if (location.used_volume === 0) {
          style['background-color'] = '#82b1ff'
        } else if (location.used_volume <= 50) {
          style['background-color'] = '#6ff66f'
        } else if (location.used_volume <= 80) {
          style['background-color'] = '#f8f822'
        } else if (location.used_volume > 80) {
          style['background-color'] = '#ff3e3e'
        }
      }
      return style */
    },
    setStyleByVolume(zone, cell_id, volume, active, soft_delete) {
      if (!active || soft_delete) {
        this.styles[`${zone}_${cell_id}`] = "#222";
      } else if (volume === null) {
        this.styles[`${zone}_${cell_id}`] = "#ffffff";
      } else if (volume === 0) {
        this.styles[`${zone}_${cell_id}`] = "#82b1ff";
      } else if (volume <= 50) {
        this.styles[`${zone}_${cell_id}`] = "#6ff66f";
      } else if (volume <= 80) {
        this.styles[`${zone}_${cell_id}`] = "#f8f822";
      } else if (volume > 80) {
        this.styles[`${zone}_${cell_id}`] = "#ff3e3e";
      }
    },
    setStyleDeactivate(zone, cell_id) {
      this.styles[`${zone}_${cell_id}`] = "#c9c9c9";
    },
    filterList() {
      let countUsed = 0;
      const locations = [...this.dLocations].map((l) => {
        if (l.id_goods) {
          l.used_volume =
            ((l.goods_volume || 0) / l.storage_calculation_volume) * 100;
          if (
            this.filters.id_customers &&
            this.filters.id_customers.length > 0 &&
            !this.filters.id_customers.includes(+l.id_customer)
          ) {
            this.setStyleDeactivate(l.zone, l.cell_id);
          } else {
            if (this.filters.zones && this.filters.zones.length > 0) {
              if (this.filters.zones.includes(l.zone)) {
                countUsed++;
              }
            } else {
              countUsed++;
            }
            this.setStyleByVolume(
              l.zone,
              l.cell_id,
              l.used_volume,
              l.active,
              l.soft_delete
            );
          }
        } else {
          l.used_volume = null;
          this.setStyleByVolume(
            l.zone,
            l.cell_id,
            l.used_volume,
            l.active,
            l.soft_delete
          );
        }
        return l;
      });
      this.countUsed = countUsed;
      if (this.filters.zones && this.filters.zones.length > 0) {
        this.countTotal = this.dLocations.filter(
          (l) =>
            this.filters.zones.includes(l.zone) && l.active && !l.soft_delete
        ).length;
      } else {
        this.countTotal = this.dLocations.length;
      }
      this.styles = { ...this.styles };

      let racks = [...this.dRacks];
      if (this.filters.zones && this.filters.zones.length > 0) {
        racks = [...racks].filter((r) => this.filters.zones.includes(r.zone));
      }

      racks = [...racks].map((r) => {
        const rows = [];
        for (let row = r.max_level - 1; row >= 0; row--) {
          const cells = [];
          for (let cell = r.start_cell; cell <= r.max_cell_each_level; cell++) {
            if (cell != 0) {
              const cell_id = this.getPositionCode(r, row, cell);
              const cell_style = this.getCellStyle(r, cell_id);
              cells.push({
                cell_id,
                cell_style,
              });
            }
          }
          rows.push({
            row,
            cells,
          });
        }
        r.rows = [...rows];
        return r;
      });
      this.locations = [...locations];
      this.racks = [...racks];
    },
    getList: debounce(function () {
      if (
        !this.filters.id_warehouses ||
        this.filters.id_warehouses.length === 0
      ) {
        this.racks = [];
        this.locations = [];
        return false;
      }
      httpClient
        .post("/warehouse-location/v1/get-for-draw", this.filters)
        .then(({ data }) => {
          const { racks, locations /*count_used, count_total*/ } = data;
          this.dLocations = [...locations];
          this.dRacks = [...racks];
          /*this.countUsed = count_used
        this.countTotal = count_total*/
          this.filterList();
        });
    }, 500),
  },
};
</script>

<style scoped lang="scss">
.td-item {
  border: 1px solid #666;
  text-align: center;
}
</style>
